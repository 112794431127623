<template>
    <div>
        <el-row type="flex" class="row-bg mainContent" justify="space-between">
            <el-col :span="14" class="mainLeft">
                <div class="title">
                    <div :class="$i18n.locale==='zh'?'slogan':'slogan sloganE'" v-html="$t('login.slogan1')"></div>
                    <span>{{ $t('login.slogan2') }}</span>
                </div>
                <div class="logo bgCommon"></div>
            </el-col>
            <el-col :span="10" class="mainRight">
                <div class="main">
                    <h1 class="logo" @click="$router.replace({path:'/'})">
                        <img src="@/assets/images/capital/bigLogo.png" alt=""/>
                    </h1>
                    <div class="tab">
                        <ul :class="{'ulEn': $i18n.locale==='en' }">
                            <li :class="active == 0 ? 'active' : ''" @click="tab(0)">
                                {{ $t('login.tab1') }}
                            </li>
                            <li :class="active == 1 ? 'active' : ''" @click="tab(1)">
                                {{ $t('login.tab2') }}
                            </li>
                        </ul>
                    </div>
                    <div class="form-mobile login-area">
                        <div class="from">
                            <div :class="form.loginNamemsg ? 'form-item form-item-error' : 'form-item'">
                                <label><img src="@/assets/images/login/user.png"/></label>
                                <input
                                    type="text"
                                    v-model="form.loginName"
                                    :placeholder="placeholder"
                                    autocomplete="off"
                                />
                                <p class="error" v-if="form.loginNamemsg">
                                    <img src="@/assets/images/login/wrong.png"/>
                                    {{ form.loginNamemsg }}
                                </p>
                            </div>
                            <div :class="form.passwordmsg ? 'form-item form-item-error' : 'form-item'" v-show="active == 0">
                                <label><img src="@/assets/images/login/password.png"/></label>
                                <input type="password" v-model="form.password" :placeholder="$t('login.password')" autocomplete="off"/>
                                <p class="error" v-if="form.passwordmsg">
                                    <img src="@/assets/images/login/wrong.png"/>{{ form.passwordmsg }}
                                </p>
                            </div>
                            <Verification @verification="verification" ref="Verification"/>
                            <div class="yzm-item" v-show="active == 1">
                                <div :class="form.codemsg ? 'form-item form-item-error' : 'form-item'">
                                    <label><img src="@/assets/images/login/email.png" style="width: 22px; height: 23px"/></label>
                                    <input type="text" v-model="form.code" autocomplete="off" :placeholder="$t('login.code')"/>
                                    <p class="error" v-if="form.codemsg">
                                        <img src="@/assets/images/login/wrong.png"/>
                                        {{ form.codemsg }}
                                    </p>
                                </div>
                                <span class="yzmbtn" :disabled="disabled" @click="getVerificationCode()">{{ yzmtext }}</span>
                            </div>

                            <div class="read" v-show="active == 1">
                                <input type="checkbox" v-model="checked" class="checkbox" id="checkbox"/>
                                <label for="checkbox" class="checkboxlabel">
                                    <span class="checkboxspan" v-if="checked">
                                        <img src="@/assets/images/login/checkbox1.png"/>
                                    </span>
                                    <span class="checkboxspan" v-else>
                                        <img src="@/assets/images/login/checkbox2.png"/>
                                    </span>
                                </label>
                                <label for="read" v-show="active == 1">
                                    {{ $t('login.read') }}
                                    <a :href="regAgreement" target="_blank">{{ $t('login.regAgreement') }}</a>
                                    {{ $t('login.and') }}
                                    <a :href="privacyAgreement" target="_blank">{{ $t('login.privacyAgreement') }}</a>
                                </label>
                            </div>
                            <div class="form-btn" @click="loginTo" style="margin-top:33px">
                                <span>{{ $t('login.signIn') }}</span>
                            </div>
                            <div class="forget" style="text-align: left; margin-top: 25px">
                                <span @click="regPage">{{ $t('login.goReg') }}</span>
                                <span @click="forgetPage" style="float:right">{{ $t('login.forgetPwd') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
        <el-dialog
            :visible.sync="privacyDialog"
            :width="dialogWidth"
            class="hint-dialog"
            :show-close="false"
            :close-on-click-modal="false"
            :key="timer+'1'"
            center
        >
            <div class="content">
                <iframe :src="privacyAgreement" id="privacy" class="frame" frameborder="0" width="100%"></iframe>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="nologin">取 消</el-button>
                    <el-button type="primary" @click="agree('PRIVACY')">同 意</el-button>
                </span>
            </div>
        </el-dialog>
        <el-dialog
            :visible.sync="regDialog"
            :width="regdialogWidth"
            class="reg-update-dialog"
            :show-close="false"
            :close-on-click-modal="false"
            center
        >
            <div class="content">
                <div class="dialog-title">协议更新</div>
                <RegDocUpdateTips @openRegistrationDialog='registrationDialog=true,regDialog=false,timer = new Date().getTime()' />
                <span slot="footer" class="dialog-footer">
                    <el-button @click="cancelDialog()">取 消</el-button>
                    <el-button type="primary" @click="agree('SIGNUP')">确定</el-button>
                </span>
            </div>
        </el-dialog>
        <el-dialog
            :visible.sync="registrationDialog"
            :width="dialogWidth"
            class="hint-dialog"
            :show-close="false"
            :close-on-click-modal="false"
            center
            :key="timer"
        >
            <div class="content">
                <iframe :src="regAgreement" id="reg" class="frame" frameborder="0" width="100%"></iframe>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="regDialog=true,registrationDialog=false">返回</el-button>
                </span>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import qs from "qs";
var interval = null;
import Verification from "@/components/Verification/loginV";
import {loginApi, sendMsgApi, getMeApi, logoutApi, signProtocol} from "@/api/service";
import RegDocUpdateTips from '@/components/RegDocUpdateTips.vue';
import md5 from 'js-md5'
export default {
    name: "login",
    components: {Verification,RegDocUpdateTips},

    data() {
        return {
            timer:new Date().getTime(),
            deviceType:'PC',
            regAgreement:process.env.VUE_APP_USERCENTERUI+'/agreement/registration',
            privacyAgreement:process.env.VUE_APP_USERCENTERUI+'/agreement/privacy',
            active: 0,
            checked: false,
            form: {
                loginName: "",
                loginNamemsg: "",
                password: "",
                passwordmsg: "",
                code: "",
                codemsg: "",
            },
            loginType: "LOGIN_NAME",
            disabled: false,
            currentTime: 60,
            verify: false,
            nvc:'',
            clientId: 952310,
            access_token:'',
            yzmtext:this.$t("login.getCode"),
            placeholder:this.$t("login.userName"),
            dialogWidth:'80%',
            regdialogWidth:'75%',
            privacyDialog:false,
            regDialog:false,
            registrationDialog:false,
            signupProtocolUpdateStatus:'',
            protocolUpdateStatus:'',
        };
    },
    computed: {
        // getYzmtext:{
        //     get(){
        //         return this.yzmtext
        //     },
		// 	set(value){
        //         this.yzmtext=value;
        //     } 
        // },
        // placeholder:{
        //     get(){
        //         return  this.$t("login.userName")
        //     },
		// 	set(value){}
        // }
    },
    created() {
        this.clientId = this.$getlocalStorage("queryData")? this.$getlocalStorage("queryData").clientId: 952310;
    },

    mounted() {
        this.resize();
        window.addEventListener('resize', this.resize)
        if (this.deviceType=='MOBILE') {
            this.dialogWidth = '95%';
            this.regdialogWidth='80%';
        } else {
            this.dialogWidth = "80%";
            this.regdialogWidth='40%';
        }
    },
    deactivated () {
        window.removeEventListener('resize', this.resize)
    },
    beforeDestroy () {
        window.removeEventListener('resize', this.resize)
    },

    methods: {
        resize () {
            if (document.body.clientWidth > 768) {
                this.deviceType = 'PC';
            } else {
                this.deviceType = 'MOBILE';
            }
        },
        tab(n) {
            this.$refs.Verification.resetNvc();
            this.verify = false;
            this.active = n;
            this.disabled = false;
            this.yzmtext = this.$t('login.getCode');
            this.currentTime = 60;
            this.form.loginName = "";
            this.form.password = "";
            this.form.code = "";
            if (n == 0) {
                this.placeholder = this.$t("login.userName");
                this.loginType = "LOGIN_NAME";
            } else {
                this.placeholder = this.$t("login.phone");
                this.loginType = "SMS";
            }
        },
        regPage() {
            this.$router.push({
                path: "/reg",
                query: this.$route.query,
            });
        },
        forgetPage() {
            this.$router.push({
                path: "/forget",
                query: this.$route.query,
            });
        },
        isverify() {
            if (this.active == 1) {
                if (!/^1[23456789]\d{9}$/.test(this.form.loginName)) {
                    this.form.loginNamemsg = this.$t("login.verPhone");
                    return false;
                } else {
                    this.form.loginNamemsg = "";
                }
            } else {
                if (!this.form.loginName && !this.form.loginName.trim()) {
                    this.form.loginNamemsg = this.$t("login.userName");
                    return false;
                } else {
                    this.form.loginNamemsg = "";
                }
                if (!this.form.password && !this.form.password.trim()) {
                    this.form.passwordmsg = this.$t("login.password");
                    return false;
                } else {
                    this.form.passwordmsg = "";
                }
            }
            return true;
        },
        async getVerificationCode() {
            if (!this.isverify()) {
                return false;
            }
            if (!this.verify) {
                this.$diamsg(this.$t('login.varSlide'));
                return false;
            }
            this.getCode();
            var that = this;
            that.disabled = true;
            var data = qs.stringify({
                signupType: "PHONE",
                loginName: that.form.loginName,
                nvc: that.nvc
            });
            sendMsgApi(data).then((res) => {
                that.$msg(that.$t('login.sendCode'), "success");
            }).catch((error) => {
                let err=error.data;
                if (err.code === 403) {
                    that.$msg(err.message);
                    return false;
                }
                if (err.code === 100 || err.code === 200) {
                    that.$msg(that.$t('login.sendCode'), "success");
                    that.$refs.Verification.resetNvc();
                } else if (err.code === 800 || err.code === 900) {
                    // 无痕验证失败，直接拦截
                    that.$msg(that.$t("login.vError"));
                    that.disabled = false;
                    clearInterval(interval);
                    that.yzmtext = that.$t("login.againGetCode");
                    that.currentTime = 60;
                    that.disabled = false;
                } else if (err.code === 400) {
                    that.disabled = false;
                    clearInterval(interval);
                    that.yzmtext = that.$t("login.againGetCode");
                    that.currentTime = 60;
                    that.disabled = false;
                    that.$refs.Verification.resetNvc();
                }
            });
        },
        getCode() {
            var that = this;
            var currentTime = that.currentTime;
            interval = setInterval(function () {
                currentTime--;
                that.yzmtext = "(" + currentTime + "s)"+that.$t("login.againGetCode");
                if (currentTime <= 0) {
                    clearInterval(interval);
                    that.yzmtext = that.$t("login.againGetCode");
                    that.currentTime = 60;
                    that.disabled = false;
                }
            }, 1000);
        },
        async loginTo() {
            let that = this;
            if (!this.isverify()) {
                return false;
            }
            if (!this.verify) {
                this.$diamsg(this.$t('login.varSlide'));
                return false;
            }
            if (that.active == 1) {
                if (!this.checked) {
                    this.$diamsg(this.$t('login.agreement'));
                    return false;
                }
                if (!this.form.code) {
                    this.$diamsg(this.$t('login.code'));
                    return false;
                }
            }
            var params;
            let channelCode;
            if (that.$getlocalStorage("queryData")) {
                channelCode =that.$getlocalStorage("queryData").channelCode || that.$route.query.channelCode ||null;
            }
            if (that.active == 0) {
                params = qs.stringify({
                    loginName: that.form.loginName.replace(/(^\s*)|(\s*$)/g, ""),
                    loginType: that.loginType,
                    password: md5(that.form.password.replace(/(^\s*)|(\s*$)/g, "")),
                    channelCode,
                    nvc:that.nvc,
                    partnerType:1,
                });
            } else {
                let formdata = {
                    loginName: that.form.loginName.replace(/(^\s*)|(\s*$)/g, ""),
                    loginType: that.loginType,
                    password: that.form.code.replace(/(^\s*)|(\s*$)/g, ""),
                    channelCode,
                    clientId: 952310,
                    nvc:that.nvc,
                    partnerType:1,
                };
                if (!that.clientId) {
                    delete formdata.clientId;
                }
                params = qs.stringify(formdata);
            }
            var loading = that.$layer.loading({
                shade: true,
            });
            loginApi(params).then((res) => {
                that.$layer.close(loading);
                that.access_token = res.access_token;
                that.signupProtocolUpdateStatus=res.signupProtocolUpdateStatus;
                that.protocolUpdateStatus=res.protocolUpdateStatus;
                //注册协议更新需要授权 协议版本 0. 不需要弹出更新协议框, 1. 需要弹出 2. 可选
                if(res.signupProtocolUpdateStatus==2){
                    that.regDialog=true;
                    return false;
                }
                //隐私协议更新需要授权
                if(res.protocolUpdateStatus==1){
                    this.timer = new Date().getTime();
                    that.privacyDialog=true;
                    return false;
                }
                getMeApi().then((res)=>{
                    if(res.parentId==='0'){//主账号直接登录
                        that.gologin();
                    }else{//子账号需要先判断主账号是否签过协议
                        if(res.subUserProtocolUpdateStatus===1){//需要签协议
                            this.$alert('请尽快通知主账号登录系统，并在“子账号管理”页面同意《子账号开通责任条款》，否则可能影响子账号功能的使用。', '提示', {
                                confirmButtonText: '确定',
                                callback: action => {
                                    that.gologin();
                                }
                            });
                        }else{
                            that.gologin();
                        }
                    }
                }).catch((err)=>{
                    console.log(err)
                })
            }).catch((err) => {
                console.log(err);
                that.$layer.close(loading);
                err = err.data;
                that.$refs.Verification.resetNvc();
                that.verify = false;
                this.$diamsg(err.message,'error');
                return;
            });
        },
        verification(data) {
            if (data) {
                this.nvc = data.nvc;
                this.verify = true;
            }
        },
        // 隐私协议关闭弹框
        closeDialog(){
            this.privacyDialog=false
        },
        cancelDialog(){
            this.regDialog=false;
            if(this.protocolUpdateStatus==1){
                this.timer = new Date().getTime();
                this.privacyDialog=true;
            }else{
                this.gologin();
            }
        },
        agree(protocolType){
            let that=this;
            that.regDialog=false;
            that.privacyDialog=false;
            signProtocol({
                protocolType:protocolType,
                access_token:that.access_token
            }).then((res) => {
                if(res.protocolUpdateStatus==1){
                    this.timer = new Date().getTime();
                    that.privacyDialog=true;
                    return false;
                }
                that.gologin();
            }).catch((err) => {
                that.$diamsg(err.message);
            });
        },
        gologin(){
            let copyRightUrl;
            if(this.deviceType == 'PC'){
                copyRightUrl=process.env.VUE_APP_COPYRIGHT
            }else{
                copyRightUrl=process.env.VUE_APP_COPYRIGHT_M
            }
            window.location.href = copyRightUrl+"/time/ApplyTime?access_token="+this.access_token+"&language="+this.$getlocalStorage("language")+"&partnerType=1"
        },
        nologin(){
            let that=this;
            logoutApi().then((res) => {
                that.privacyDialog=false;
                that.$refs.Verification.resetNvc();
            }).catch((err) => {
                console.log(err);
            });
        },
    },
};
</script>
<style lang="less" src="@/assets/css/login.less" scoped></style>
