<template>
    <div class="doc">
        <div>
            联合信任平台删除所有协议和说明文件中涉及“联合信任享有最终解释权”的条款，除此外上述协议和说明文件无其他修改。
        </div>
        <div>
            <span class="blue" style="cursor:pointer" @click="goNext">《用户注册与使用协议》</span>删除了原版本的最后一条，请查看并确认。
        </div>
        <div>
            如对本次平台协议或文件的修改有任何疑问、意见或建议，用户可通过联合信任平台提供的各种联系方式与联合信任联系。
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
    components: {},

    computed: {},

    created() {},

    mounted() {},

    methods: {
        goNext() {
            this.$emit('openRegistrationDialog');
		}
    },
};
</script>
<style scoped>
.doc{font-size:14px;width:100%; max-width: 1200px; margin: 0 auto; padding: 25px 20px; line-height: 30px; box-sizing: border-box;}
.doc div{
    text-indent: 2em;
}
.blue{
    color:blue
}
</style>